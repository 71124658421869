import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Link } from "gatsby";
import { ByLine } from "../../layout";
import { BlogEntry, DesktopRightImage, Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 className="px-2 text-center">The market potential</h1>
    <h3 className="text-center text-lg">
  A look at the market, future plans, and potential
    </h3>
    <br />
    <Image src="/invest/kroner.jpg" mdxType="Image" />
    <br />
    <h4>{`The market`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  The market is a mix of travel tech and sports tech. With our solutions for the
  tourism industry we are in the travel tech market. With our trailguide
  mountain biking app, we are in the sports tech market.
    </div>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  Looking at the software for the tourism industry only, we have calculated the
  potential revenue in our home market to be 25 million kroner. The market
  potential for the EU+3 region is 950 million kroner.
    </div>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  These numbers are limited to our current customer types and our solutions for
  the travel industry. This can be extended to reach a bigger and wider market.
  And if one looks at the sports tech market with end-user subscriptions, the
  potential is a lot bigger.
    </div>
    <br />
    <h4>{`The future`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  Trailguide is sitting on valuable IP rights where the economical potential is
  just waiting to be unleashed by the right business team. There is a potential
  for building a multimillion dollar company.
    </div>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  What needs to be done is to build a proper business team around the
  technology, products and solutions we have made.
    </div>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  A marketing team that can tell the world about our great stuff. A sales team
  that can sell our products efficiently.
    </div>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  A customer success team that makes sure that our customers make the most of
  our solutions and give their guests and visitors good experiences.
    </div>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  A business team that can make strategic partnerships with others and make sure
  we get the most out of our potential.
    </div>
    <br />
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  There are several ways forward, some of them might be:
    </div>
    <br />
    <h4>{`A) Developing solutions for the tourism sector`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  We continue developing and improving on the solutions for the tourism sector.
  License sales will grow into a healthy ARR (Annual Recurring Revenue) with
  good market penetration. The trailguide mountain biking app will also be
  enhanced, giving customers access to crowd-sourced content.
    </div>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  There is not too much development that needs to be done to actually make our
  platform a full publishing system for a Destination Management Organizations
  (DMO).
  <br />
  <br />
  or
    </div>
    <br />
    <h4>{`B) Sell the tourism tech and grow the Trailguide MTB app`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  Another solution is to split the codebase in two. One part will be the code
  and technology needed to run the Trailguide mountain biking app. The other
  part will be the code and technology needed for the tourism sector solutions.
    </div>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  The tech needed for the tourism sector solutions can be sold off to another
  vendor of travel tech products with an in-house development team and an
  existing well-functioning business team.
    </div>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  The proceeds of such a transaction will be used towards further developing the
  Trailguide mountain biking app and grow it into an app with a huge paying user
  base. A huge user base and MRR (Monthly Recurring Revenue) from the app will
  give the app great valuation and become a prime candidate for and acquisition.
    </div>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  In November of 2021, a similar app called AllTrails received an investment of
  150 million USD, bringing the total funding to 227 million USD. They had a
  freemium model and 1 million paying users.
  <br />
  <br />
  or
    </div>
    <br />
    <h4>{`C) Go big in travel tech and sports tech`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  This is a combination of the two, but without the funds from selling parts of
  our IP rights. This will need more funds since we need additional developers,
  and money for marketing and PR of the app.
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      